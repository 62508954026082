import React, { useState, useEffect } from "react";
import Input from "../ui/input";
import Button from "../ui/button";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
export default function SignUpEarlyAccessForm() {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    setIsPending(false);
    setSuccess(false);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const router = useRouter();

  async function onSubmit({ email }) {
    setIsPending(true);
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/subscriber/sign-up`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (res.ok) {
        setSuccess(true);
      } else {
        setIsPending(false);
        setSuccess(false);
        setError("Error Signing Up. Please try again.");
      }
    } catch (err) {
      console.log(err);
    }
  }
  return success ? (
    <p className="md:text-base text-sm border-dashed border-[1px] border-black text-center py-4 px-8 md:w-[600px] w-full mx-auto mt-8 rounded-xl">
      <span className="text-2xl font-bold">Thank you!</span> <br />
      Please check your email for further instructions.
    </p>
  ) : (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center md:w-[400px] w-full mx-auto"
      noValidate
    >
      <div className="flex flex-col space-y-3.5">
        <Input
          labelKey="Email"
          type="email"
          variant="solid"
          {...register("email", {
            required: `${"Please provide your email address"}`,
            pattern: {
              value:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Please provide a valid email address",
            },
          })}
          errorKey={errors.email?.message}
        />

        <Button
          type="submit"
          loading={isPending}
          disabled={isPending}
          className="h-11 md:h-12 w-full mt-1.5"
        >
          Sign Up for Pre Sale
        </Button>

        {error && <p className="my-2 text-sm text-red">{error}</p>}
      </div>
    </form>
  );
}
