import React, { useState, useEffect } from "react";
import cn from "classnames";
import { AnimatePresence, m } from "framer-motion";
import { useRouter } from "next/router";
import SupportIcon from "@/components/icons/support-icon";
import useScrollLock from "@/utils/use-scroll-lock";
import SupportForm from "@/components/support/support-form";

export default function SupportChat() {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const router = useRouter();

  useScrollLock(open);

  // Check if the route matches /product/[slug]
  const isProductPage = router.pathname.startsWith("/products/[slug]");

  // Observe if the user has started scrolling (only on /product/[slug])
  useEffect(() => {
    if (!isProductPage) return;

    const handleScroll = () => {
      if (window.scrollY > 40 && !isScrolled) {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled, isProductPage, router?.pathname]);

  // Always show the SupportForm on all pages except /product/[slug]
  return (
    (isScrolled || !isProductPage) && (
      <m.div
        initial={{
          width: 70,
          height: 70,
          borderRadius: 1000,
        }}
        animate={{
          width: open ? "auto" : 70,
          height: open ? "auto" : 70,
          borderRadius: open ? 20 : 1000,
        }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        layout
        className={cn(
          "bg-white shadow-avatar z-[10000] fixed md:bottom-8 md:right-8 right-4 bottom-4 flex flex-col items-center justify-center overflow-hidden"
        )}
      >
        {!open && (
          <SupportIcon
            onClick={() => setOpen(true)}
            className={cn(
              "w-12 h-12 absolute transition-all duration-500 ease text-black cursor-pointer lg:hover:scale-105 transform"
            )}
          />
        )}

        <AnimatePresence>
          {open && (
            <m.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="chat-container lg:w-[600px] md:w-[calc(100vw-64px)] w-[calc(100vw-32px)] h-[500px] overflow-y-scroll flex flex-col justify-start items-start overflow-hidden"
            >
              <div className="bg-black w-full lg:px-7 md:px-6 px-4 py-3 flex items-center justify-between">
                <m.p
                  className={`text-white text-sm md:text-base ${
                    open ? "opacity-100" : "opacity-0"
                  } transition-all duration-300 ease`}
                >
                  Need Assistance?
                </m.p>
                <span
                  onClick={() => setOpen(false)}
                  className="underline text-white text-sm pb-0 leading-none hover:text-white/80 transition-all duration-300 ease cursor-pointer"
                >
                  Close
                </span>
              </div>
              <div className="flex flex-col w-full lg:px-6 lg:py-10 px-4 pb-6 pt-8">
                {/* Conditionally render SupportForm if it's a product page and user has scrolled */}

                <SupportForm showInstaButton={true} />
              </div>
            </m.div>
          )}
        </AnimatePresence>
      </m.div>
    )
  );
}
