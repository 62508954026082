import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import Section from "@/components/ui/section";
import SignUpEarlyAccessForm from "@/components/auth/sign-up-early-access-form";

export default function Timer({ data, title, subTitle, description }) {
  return (
    <div className="bg-gray-200 overflow-hidden w-full px-4 md:px-8">
      <div className="w-full flex flex-col justify-center items-center h-full">
        {/* Render TimerComponent only after the component has mounted */}
        <TimerComponent
          data={data}
          title={title}
          subTitle={subTitle}
          description={description}
        />
        {/* <SignUpEarlyAccessForm /> */}
      </div>
    </div>
  );
}

export const TimerComponent = ({ data, title, subTitle, description }) => {
  const [isMounted, setIsMounted] = useState(false);

  // Ensure the component is mounted before rendering dynamic content
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const expiry = "2024-12-20T11:30:00Z";
  let expiryTimestamp = new Date(expiry);

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      // handle expiration logic if needed
    },
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col gap-2">
        {title && (
          <h1 className="text-black lg:text-4xl font-semibold md:text-2xl text-lg text-center capitalize">
            {title}
          </h1>
        )}
        {subTitle && (
          <h2 className="mb-0 md:text-xl text-sm text-center">{subTitle}</h2>
        )}
        {description && (
          <h2 className="mb-0 md:text-xl text-sm text-center">{description}</h2>
        )}
      </div>

      <div className="mt-5 flex flex-row items-center justify-center gap-6">
        {/* Render placeholder values on the server */}
        <ValueBox label="Days" value={isMounted ? days : "--"} />
        <ValueBox label="Hours" value={isMounted ? hours : "--"} />
        <ValueBox label="Mins" value={isMounted ? minutes : "--"} />
        <ValueBox label="Secs" value={isMounted ? seconds : "--"} />
      </div>
    </div>
  );
};

export const ValueBox = ({ value, label }) => {
  const valueClass = "text-black text-3xl lining-nums";
  const labelClass = "text-black text-lg uppercase lining-nums";
  return (
    <div className="flex flex-col items-center justify-center">
      <span className={valueClass}>{value}</span>
      <span className={labelClass}>{label}</span>
    </div>
  );
};
