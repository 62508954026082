import Section from "@/components/ui/section";
import HeroBlock from "@/containers/hero-block";
import { QUERY_ENDPOINTS } from "@/framework/utils/query-endpoints";
import { NextSeo } from "next-seo";
import { getUrqlClient } from "@/lib/urql-client";
import CategoryBlock from "@/containers/category-block";
import Verified from "@/components/common/verified";
// import dynamic from "next/dynamic";
// const Timer = dynamic(() => import("react-timer-hook"), { ssr: false });
import Timer from "@/containers/timer";
import { useQuery } from "@/framework/queries/graphql";
// import InstagramFeed from "@/containers/instagram-feed";
import Faq from "@/containers/faq";
import Products from "@/containers/home/products";
import TimerHome from "@/containers/timer-home";
export default function Home({ carousel, timer, faq }) {
  return (
    <>
      <NextSeo
        title="Hype Fly India | Buy Sneakers and Streetwear"
        defaultTitle="Hype Fly India | Buy Sneakers and Streetwear"
        description="India's Trusted Source for Authentic Sneakers and Streetwear. Shop from a wide range of brands - Jordans, Yeezys, Dunks, Fear of God Essentials at HypeFly India."
        canonical="https://hypefly.co.in/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          site_name: "Hypefly",
          url: "https://hypefly.co.in",
          title: "Hype Fly India | Buy Sneakers and Streetwear",
          description:
            "India's Trusted Source for Authentic Sneakers and Streetwear. Shop from a wide range of brands - Jordans, Yeezys, Dunks, Fear of God Essentials at HypeFly India.",
          images: [
            {
              url: "https://i.imgur.com/HzS2zef.png",
              width: 140,
              height: 140,
              alt: "Hypefly",
            },
          ],
        }}
      />
      <BannerSection carousel={carousel} variant="normal" />
      {new Date(timer?.expiry) > new Date() && <TimerHome data={timer} />}

      <Products
        collectionSlug={"home-page-all-sneakers"}
        sectionHeading={"Sneakers"}
        categorySlug="/collections/all-sneakers"
      />
      <CategoriesSection />
      <GrailsCarousel />
      <GrailsMiniCarousel />
      <Products
        collectionSlug={"running"}
        sectionHeading={"Running"}
        categorySlug="/collections/running"
      />
      <StreetwearCarousel />
      <Products
        collectionSlug={"apparel-home-page"}
        sectionHeading={"Streetwear"}
        categorySlug="/collections/all-apparel"
      />

      <Products
        collectionSlug={"all-accessories"}
        sectionHeading={"Accessories"}
        categorySlug="/collections/all-accessories"
      />

      <Section variant="flat">
        <Verified className="mt-8" />
      </Section>
      <Faq data={faq} />
    </>
  );
}
const BannerSection = ({ carousel }) => {
  return carousel && <HeroBlock data={carousel} dots={true} />;
};
const StreetwearCarousel = () => {
  const [{ data, fetching: loading, error }] = useQuery({
    query: QUERY_ENDPOINTS.HOME_STREET_CAROUSEL,
  });
  return (
    <div className="pt-12 w-full">
      <HeroBlock
        data={data?.homeStreetwearCarousel?.data?.attributes?.carousel}
        dots={
          data?.homeStreetwearCarousel?.data?.attributes?.carousel?.length > 1
            ? true
            : false
        }
        variant={"small"}
        loading={!data?.homeStreetwearCarousel?.data?.attributes?.carousel}
      />
    </div>
  );
};

const GrailsCarousel = () => {
  const [{ data, fetching, error }] = useQuery({
    query: QUERY_ENDPOINTS.HOME_ACCESSORIES_CAROUSEL,
  });
  return (
    <div className="w-full pb-12">
      <HeroBlock
        data={data?.homeAccessoriesCarousel?.data?.attributes?.carousel}
        loading={!data?.homeAccessoriesCarousel?.data?.attributes?.carousel}
        dots={
          data?.homeAccessoriesCarousel?.data?.attributes?.carousel?.length > 1
            ? true
            : false
        }
        variant={"runningHome"}
      />
    </div>
  );
};
const GrailsMiniCarousel = () => {
  const [{ data, fetching: loading, error }] = useQuery({
    query: QUERY_ENDPOINTS.HOME_GRAILS_MINI_CAROUSEL,
  });
  const responsive = [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 2.3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2.3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.7,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.2,
      },
    },
  ];
  return (
    data && (
      <div className="w-full pl-4 md:pl-8 lg:pl-[5vw] mt-12">
        <HeroBlock
          responsive={responsive}
          data={data?.homeGrailsMiniCarousel?.data?.attributes?.carousel}
          dots={false}
          slidesPerView={2.3}
          variant={"mini"}
          loading={!data?.homeGrailsMiniCarousel?.data?.attributes?.carousel}
        />
      </div>
    )
  );
};
const CategoriesSection = ({ categories }) => {
  return (
    <CategoryBlock
      productCategories={categories}
      sectionHeading="Shop By Category"
      type="rounded"
      loading={false}
      slugPrefix={"/collections/all-sneakers?category="}
    />
  );
};

export async function getStaticProps() {
  const client = await getUrqlClient();

  let data = {};

  try {
    const [carouselResponse, timerResponse, faqResponse] = await Promise.all([
      fetch(
        `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/home-carousel?populate=deep`
      ),
      fetch(`${process.env.NEXT_PUBLIC_STRAPI_URL}/api/timer`),
      client.query(QUERY_ENDPOINTS.FAQ, { requestPolicy: "network-only" }),
    ]);

    if (!carouselResponse.ok) {
      throw new Error(`HTTP error! status: ${carouselResponse.status}`);
    }
    data.carousel = await carouselResponse.json();

    if (!timerResponse.ok) {
      throw new Error(`HTTP error! status: ${timerResponse.status}`);
    }
    data.timer = await timerResponse.json();

    data.faq = faqResponse.data?.faqs?.data ?? [];
  } catch (err) {
    console.error("Error fetching data:", err.message);
  }

  return {
    props: {
      carousel: data?.carousel?.data?.attributes?.Carousel ?? null,
      timer: data?.timer?.data?.attributes ?? null,
      faq: data?.faq ?? [],
    },
    revalidate: 10, // Revalidate every 10 seconds
  };
}
