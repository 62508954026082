import { useState } from "react";
import Link from "next/link";
import { siteSettings } from "@/settings/site-settings";
import Scrollbar from "@/components/common/scrollbar";
import Envelope from "@/components/icons/envelope";

import ChevronDown from "@/components/icons/chevron-down";
import Logo from "@/components/ui/logo";
import { useUI } from "@/contexts/ui.context";
import CloseIcon from "@/components/icons/close-icon";
import InstagramOutline from "@/components/icons/instagram-outline";
import FacebookFilled from "@/components/icons/facebook-filled";
const social = [
  {
    id: 1,
    link: "https://www.instagram.com/hypefly.in/",
    icon: <InstagramOutline className="facebook w-5 h-5" />,
    className: "instagram text-xl",
    title: "Instagram",
  },
  {
    id: 2,
    link: "https://www.facebook.com/hypefly.in/",
    icon: <FacebookFilled className="facebook w-5 h-5" />,
    className: "facebook text-xl",
    title: "Facebook",
  },
  {
    id: 3,
    link: "mailto:contact@hypefly.co.in",
    icon: <Envelope className="youtube w-5 h-5" />,
    className: "youtube text-xl",
    title: "Email",
  },
];

export default function MobileMenu() {
  const [activeMenus, setActiveMenus] = useState([]);
  const { site_header } = siteSettings;
  const { closeSidebar } = useUI();
  const handleArrowClick = (menuName) => {
    let newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      var index = newActiveMenus.indexOf(menuName);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuName);
    }

    setActiveMenus(newActiveMenus);
  };

  const ListMenu = ({
    dept,
    data,
    hasSubMenu,
    menuName,
    menuIndex,
    className = "",
  }) =>
    data.label && (
      <li className={`mb-0.5 ${className}`}>
        <div className="relative flex items-center justify-between">
          {data?.path ? (
            <Link
              href={data.path}
              className="w-full md:text-base text-[#4A5568] text-[15px] menu-item relative py-3 pl-5 md:pl-6 pr-4 transition duration-300 ease-in-out"
            >
              <span
                className={`block w-full ${
                  data.label === "SECRET STEALS SALE"
                    ? "font-bold uppercase"
                    : "font-normal"
                }`}
                onClick={closeSidebar}
              >
                {data.label}
              </span>
            </Link>
          ) : (
            <div
              href={data.path}
              className="w-full md:text-base text-[#4A5568] text-[15px] menu-item relative py-3 pl-5 md:pl-6 pr-4 transition duration-300 ease-in-out"
            >
              <span
                className={`block w-full ${
                  data.label === "SECRET STEALS SALE"
                    ? "font-bold uppercase"
                    : "font-normal"
                }`}
                onClick={closeSidebar}
              >
                {data.label}
              </span>
            </div>
          )}
          {hasSubMenu && (
            <ChevronDown
              className={`transition duration-300 ease transform text-heading arrow mr-4 w-[20px] flex justify-end items-center ${
                activeMenus.includes(menuName) ? "-rotate-180" : "rotate-0"
              }`}
              onClick={() => handleArrowClick(menuName)}
            />
          )}
        </div>
        {hasSubMenu && (
          <SubMenu
            dept={dept}
            data={data.subMenu}
            toggle={activeMenus.includes(menuName)}
            menuIndex={menuIndex}
          />
        )}
      </li>
    );
  const SubMenu = ({ dept, data, toggle, menuIndex }) => {
    if (!toggle) {
      return null;
    }

    dept = dept + 1;

    return (
      <ul className="pt-0.5 ml-5">
        {data?.map((menu, index) => {
          const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

          return (
            <ListMenu
              dept={dept}
              data={menu}
              hasSubMenu={menu.subMenu}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
              className={dept > 1 && "pl-2"}
            />
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between w-full h-full">
        <div className="w-full border-b border-gray-100 flex justify-between items-center relative pl-4 md:pl-12 flex-shrink-0 md:py-2 py-0.5">
          <Logo />

          <button
            className="flex items-center justify-center md:px-8 px-4 py-4 text-2xl text-gray-500 transition-opacity focus:outline-none hover:opacity-60"
            onClick={closeSidebar}
            aria-label="close"
          >
            <CloseIcon className="text-black mt-1 md:mt-0.5 w-6" />
          </button>
        </div>

        <Scrollbar className="mb-auto menu-scrollbar">
          <div className="w-full flex flex-col md:px-6 px-0 py-7 lg:px-2 text-heading">
            <ul className="mobileMenu">
              {site_header.menu.map((menu, index) => {
                const dept = 1;
                const menuName = `sidebar-menu-${dept}-${index}`;

                return (
                  <ListMenu
                    dept={dept}
                    data={menu}
                    hasSubMenu={menu.subMenu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                );
              })}
            </ul>
          </div>
        </Scrollbar>

        <div className="flex items-center justify-center flex-shrink-0 bg-white border-t border-gray-100 px-7 gap-x-1">
          {social?.map((item, index) => (
            <a
              href={item.link}
              className={`text-heading p-5 opacity-60 first:-ml-4 transition duration-300 ease-in hover:opacity-100 ${item.className}`}
              target="_blank"
              key={index}
            >
              <span className="sr-only">{item.title}</span>
              {item.icon}
            </a>
          ))}
        </div>
      </div>
    </>
  );
}
