import { siteSettings } from "@/settings/site-settings";
import UpArrow from "@/components/icons/up-arrow";
const year = new Date().getFullYear();
import React from "react";

export default function Copyright() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="flex flex-row text-center items-center gap-4 justify-center w-fit lg:pr-[5vw]">
      <p className={"text-body text-sm lg:text-sm leading-6 mb-0 text-white"}>
        &copy; {year}&nbsp;
        <a
          className="font-semibold text-white transition-colors duration-200 ease-in-out hover:underline"
          href={siteSettings.author.websiteUrl}
        >
          {siteSettings.author.name}
        </a>
        &nbsp; All rights reserved
      </p>

      <div
        onClick={scrollToTop}
        className="cursor-pointer group lg:block hidden"
      >
        <UpArrow />
        {/* <AiOutlineArrowUp className="inline ml-3 group-hover:-translate-y-[2px] transition-all duration-300 ease" /> */}
      </div>
    </div>
  );
}
