import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
export default function TopBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const router = useRouter();

  // Check if the route matches /product/[slug]
  const isProductPage = router.pathname.startsWith("/products/[slug]");

  // Observe if the user has started scrolling (only on /product/[slug])
  useEffect(() => {
    if (!isProductPage) return;

    const handleScroll = () => {
      if (window.scrollY > 40 && !isScrolled) {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled, isProductPage, router?.pathname]);
  return (
    (isScrolled || !isProductPage) && (
      <Link
        id="topBar"
        href={"/collections/all-sneakers"}
        className="flex justify-center items-center hover:text-white/80 transition-all duration-300 ease text-white md:text-sm text-[10px] font-avenir font-medium text-center w-full bg-[#1A202C] lg:h-10 h-8"
      >
        INDIA’S LARGEST COLLECTION OF SNEAKERS | SHOP THE BEST DEALS NOW
      </Link>
    )
  );
}
