import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import Section from "@/components/ui/section";
import SignUpEarlyAccessForm from "@/components/auth/sign-up-early-access-form";
import Link from "next/link";
import Button from "@/components/ui/button";
export default function TimerHome({ data, title, subTitle, description }) {
  return (
    <div className="bg-gray-200 overflow-hidden w-full px-4 md:px-8">
      <div className="w-full flex flex-col justify-center items-center h-full">
        {/* Render TimerComponent only after the component has mounted */}
        <TimerComponent
          data={data}
          title={title}
          subTitle={subTitle}
          description={description}
        />
        {/* <SignUpEarlyAccessForm /> */}
      </div>
    </div>
  );
}

export const TimerComponent = ({ data, title, subTitle, description }) => {
  const [isMounted, setIsMounted] = useState(false);

  // Ensure the component is mounted before rendering dynamic content
  useEffect(() => {
    setIsMounted(true);
  }, []);

  let expiryTimestamp = new Date(data?.expiry);

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      // handle expiration logic if needed
    },
  });

  return (
    <Section className="bg-gray-200 overflow-hidden py-8 md:py-12">
      {data?.title && (
        <h4 className="text-black lg:text-3xl font-semibold md:text-2xl text-lg text-center capitalize">
          {data?.title}
        </h4>
      )}

      <div className="mt-5 flex flex-row items-center justify-center gap-6">
        {/* Render placeholder values on the server */}
        <ValueBox label="Days" value={isMounted ? days : "--"} />
        <ValueBox label="Hours" value={isMounted ? hours : "--"} />
        <ValueBox label="Mins" value={isMounted ? minutes : "--"} />
        <ValueBox label="Secs" value={isMounted ? seconds : "--"} />
      </div>
      <Link href={data?.buttonLink ?? "#"} className="mx-auto">
        <Button className="w-fit mx-auto mt-8">
          {data?.buttonText ?? "Click Here"}
        </Button>
      </Link>
    </Section>
  );
};

export const ValueBox = ({ value, label }) => {
  const valueClass = "text-black text-3xl lining-nums";
  const labelClass = "text-black text-lg uppercase lining-nums";
  return (
    <div className="flex flex-col items-center justify-center">
      <span className={valueClass}>{value}</span>
      <span className={labelClass}>{label}</span>
    </div>
  );
};
