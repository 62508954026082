import React from "react";
import Copyright from "./copyright";
import Link from "next/link";
import Image from "next/image";
import Envelope from "@/components/icons/envelope";
import InstagramOutline from "@/components/icons/instagram-outline";
import FacebookFilled from "@/components/icons/facebook-filled";
import { useRouter } from "next/router";
const FooterNew = () => {
  const router = useRouter();
  const quickLinks = {
    title: "Quick Links",
    links: [
      { name: "About Us", url: "https://hypefly.co.in/about" },
      { name: "Contact Us", url: "https://hypefly.co.in/support" },
      {
        name: "Authentication Process",
        url: "https://hypefly.co.in/authentication-process",
      },
      {
        name: "Shipping Policy",
        url: "https://hypefly.co.in/policies/shipping-policy",
      },
      {
        name: "Terms & Conditions",
        url: "https://hypefly.co.in/policies/terms-of-service",
      },
      {
        name: "Privacy Policy",
        url: "https://hypefly.co.in/policies/privacy-policy",
      },
      {
        name: "Return Policy",
        url: "https://hypefly.co.in/policies/return-policy",
      },
    ],
  };

  const productCategories = {
    title: "Popular Products",
    sections: {
      yeezy: {
        title: "Yeezy",
        links: [
          {
            name: "Yeezy 350",
            url: "https://hypefly.co.in/collections/yeezy-350",
          },
          {
            name: "Yeezy 380",
            url: "https://hypefly.co.in/collections/yeezy-380",
          },
          {
            name: "Foamrunner",
            url: "https://hypefly.co.in/collections/yeezy-foamrunner",
          },
          {
            name: "Slide",
            url: "https://hypefly.co.in/collections/yeezy-slide",
          },
          {
            name: "Yeezy 450",
            url: "https://hypefly.co.in/collections/yeezy-450",
          },
          {
            name: "Yeezy 700",
            url: "https://hypefly.co.in/collections/yeezy-700",
          },
          {
            name: "Yeezy 500",
            url: "https://hypefly.co.in/collections/yeezy-500",
          },
        ],
      },
      airJordan: {
        title: "Air Jordan",
        links: [
          {
            name: "Air Jordan 1 Low",
            url: "https://hypefly.co.in/collections/air-jordan-1-low",
          },
          {
            name: "Air Jordan 1 Mid",
            url: "https://hypefly.co.in/collections/air-jordan-1-mid",
          },
          {
            name: "Air Jordan 1 High",
            url: "https://hypefly.co.in/collections/air-jordan-1-high",
          },
          {
            name: "Air Jordan 2",
            url: "https://hypefly.co.in/collections/air-jordan-2",
          },
          {
            name: "Air Jordan 3",
            url: "https://hypefly.co.in/collections/air-jordan-3",
          },
        ],
      },
      nike: {
        title: "Nike",
        links: [
          {
            name: "Nike Dunk High",
            url: "https://hypefly.co.in/collections/dunk-high",
          },
          {
            name: "Nike Dunk Low",
            url: "https://hypefly.co.in/collections/dunk-low",
          },
          {
            name: "Nike Air Force 1",
            url: "https://hypefly.co.in/collections/air-force-1",
          },
          {
            name: "Alpha Fly",
            url: "https://hypefly.co.in/collections/alpha-fly",
          },
          {
            name: "Infinity Run",
            url: "https://hypefly.co.in/collections/infinity-run-4",
          },
          {
            name: "Invincible 3",
            url: "https://hypefly.co.in/collections/invincible-3",
          },
          {
            name: "Pegasus 41",
            url: "https://hypefly.co.in/collections/pegasus-41",
          },
        ],
      },
      asics: {
        title: "ASICS",
        links: [
          { name: "Gel NYC", url: "https://hypefly.co.in/collections/gel-nyc" },
          {
            name: "Cumulus 26",
            url: "https://hypefly.co.in/collections/gel-cumulus-26",
          },
          {
            name: "Kayano Gel",
            url: "https://hypefly.co.in/collections/gel-kayano-31",
          },
          {
            name: "Gel Nimbus",
            url: "https://hypefly.co.in/collections/gel-nimbus-26",
          },
          {
            name: "GT 2000",
            url: "https://hypefly.co.in/collections/gt-2000-12",
          },
          {
            name: "Metaspeed",
            url: "https://hypefly.co.in/collections/metaspeed-sky",
          },
        ],
      },
      newBalance: {
        title: "New Balance",
        links: [
          {
            name: "New Balance 550",
            url: "https://hypefly.co.in/collections/new-balance-nb-550",
          },
          {
            name: "New Balance 530",
            url: "https://hypefly.co.in/collections/new-balance-nb-530",
          },
          {
            name: "New Balance 2002R",
            url: "https://hypefly.co.in/collections/new-balance-nb-2002r",
          },
          {
            name: "New Balance 9060",
            url: "https://hypefly.co.in/collections/new-balance-nb-9060",
          },
        ],
      },
      onRunning: {
        title: "ON Running",
        links: [
          {
            name: "Cloud 5",
            url: "https://hypefly.co.in/collections/on-running-cloud-5",
          },
          {
            name: "Cloud Monster",
            url: "https://hypefly.co.in/collections/on-running-cloud-monster",
          },
          {
            name: "Cloud Monster 2",
            url: "https://hypefly.co.in/collections/on-running-cloud-monster-2",
          },
          {
            name: "Cloud Nova",
            url: "https://hypefly.co.in/collections/on-running-cloud-nova",
          },
          {
            name: "Cloud Runner",
            url: "https://hypefly.co.in/collections/cloud-runner-2",
          },
        ],
      },
      hoka: {
        title: "Hoka",
        links: [
          {
            name: "Arahi",
            url: "https://hypefly.co.in/collections/hoka-arahi-7",
          },
          {
            name: "Bondi 8",
            url: "https://hypefly.co.in/collections/hoka-bondi-8",
          },
          {
            name: "Challenger 7",
            url: "https://hypefly.co.in/collections/hoka-challenger-7",
          },
          {
            name: "Clifton 9",
            url: "https://hypefly.co.in/collections/hoka-clifton-9",
          },
          {
            name: "Mach 6",
            url: "https://hypefly.co.in/collections/hoka-mach-6",
          },
        ],
      },
      streetwear: {
        title: "Streetwear",
        links: [
          {
            name: "Represent",
            url: "https://hypefly.co.in/collections/represent",
          },
          { name: "Stussy", url: "https://hypefly.co.in/collections/stussy" },
          {
            name: "Eric Emanuel",
            url: "https://hypefly.co.in/collections/eric-emanuel",
          },
          {
            name: "Essentials",
            url: "https://hypefly.co.in/collections/essentials",
          },
          {
            name: "Drew House",
            url: "https://hypefly.co.in/collections/drew-house",
          },
          {
            name: "Palm Angels",
            url: "https://hypefly.co.in/collections/palm-angels",
          },
        ],
      },
      accessories: {
        title: "Accessories",
        links: [
          {
            name: "Rhode Phone Cases",
            url: "https://hypefly.co.in/collections/rhode-beauty",
          },
          {
            name: "Stanley Pro Tumbler",
            url: "https://hypefly.co.in/collections/stanley",
          },
          {
            name: "Ray Ban Meta Smart Glasses",
            url: "https://hypefly.co.in/collections/ray-ban-meta-headliner-low-bridge-fit",
          },
          { name: "Whoop", url: "https://hypefly.co.in/collections/whoop" },
          { name: "Funko", url: "https://hypefly.co.in/collections/funko" },
        ],
      },
    },
  };

  return (
    <footer className="left-0 w-full z-[0] sticky md:-bottom-80 -bottom-[1250px]">
      <div className="bg-[#1A202C] pt-12 mt-16 md:mt-12 px-4 md:px-8 lg:px-[5vw]">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Quick Links Section */}
          <div className="lg:col-span-3 flex lg:flex-col flex-row justify-start items-start">
            <div className="flex flex-col w-full">
              <h2 className="lg:text-[1.2vw] text-lg font-bold mb-4 text-white">
                {quickLinks.title}
              </h2>
              <ul className="space-y-3">
                {quickLinks.links.map((link) => (
                  <li key={link.name}>
                    <a
                      href={link.url}
                      className="text-white/70 hover:text-white transition-all duration-300 ease hover:underline w-fit"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <LogoContainer />
          </div>

          {/* Product Categories Section */}
          <div className="lg:col-span-9">
            <h2 className="lg:text-[1.1vw] text-lg font-bold mb-4 text-white">
              {productCategories.title}
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
              {Object.entries(productCategories.sections).map(
                ([key, section]) => (
                  <div
                    key={key}
                    className="flex flex-col items-start justify-start"
                  >
                    <h3 className="lg:text-[1vw] text-lg font-bold mb-4 text-white">
                      {section.title}
                    </h3>
                    <ul className="space-y-1.5">
                      {section.links.map((link) => (
                        <li key={link.name}>
                          <a
                            href={link.url}
                            className="text-white/70 hover:text-white transition-all duration-300 ease hover:underline w-fit"
                          >
                            {link.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        {/* Terminal Section */}
        <div className="mt-6 py-4 border-t border-white w-full">
          <div className="w-full flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <Link
              href="/"
              className="lg:w-40 lg:h-20 md:h-16 h-12 w-40 relative mb-4 md:mb-0"
            >
              <Image
                src={"/hypefly-logo-white.png"}
                alt={"Hypefly"}
                fill
                sizes="(max-width: 768px) 160px, (max-width: 1200px) 224px, 224px"
                className="object-contain lg:object-left object-center"
                priority
              />
            </Link>
            <Copyright />
          </div>
        </div>
      </div>
    </footer>
  );
};
const LogoContainer = () => {
  const iconClass = "text-white w-5 h-5";
  const SocialData = [
    {
      label: "Instagram",
      link: "https://www.instagram.com/hypefly.in/",
      icon: <InstagramOutline className={iconClass} />,
    },
    {
      label: "Facebook",
      link: "https://www.facebook.com/hypefly.in/",
      icon: <FacebookFilled className={iconClass} />,
    },
    {
      label: "Email",
      link: "mailto:contact@hypefly.co.in",
      icon: <Envelope className={iconClass} />,
    },
  ];
  return (
    <div className="md:flex hidden flex-col lg:items-start items-center justify-between gap-2 col-span-2 w-full md:mt-0 mt-20">
      <div className="grid grid-cols-3 auto-rows-auto gap-6 w-fit lg:mt-3 md:mt-0 mt-3 mb-4">
        {SocialData?.map((row, key) => (
          <SocialButton link={row.link} key={row.label + key} title={row.label}>
            {row.icon}
          </SocialButton>
        ))}
      </div>
      <div className="flex flex-col w-full lg:items-start items-center justify-start gap-1 mt-4">
        <h3 className="text-lg font-semibold mb-4 text-white">
          Business Inquiries
        </h3>
        <a
          target="_blank"
          className="text-white/70 hover:text-white text-base hover:underline transition-all duration-300 ease w-fit"
          href="mailto:contact@hypefly.co.in"
        >
          contact@hypefly.co.in
        </a>
      </div>
      <div className="flex flex-col w-full lg:items-start items-center justify-start gap-1 mt-4">
        <h3 className="text-lg font-semibold mb-4 text-white">
          Registered Address
        </h3>
        <div className="text-white/70 hover:text-white text-base hover:underline transition-all duration-300 ease w-fit lg:text-left text-center lg:max-w-[350px] md:max-w-[400px]">
          HypeFly India Private Limited
          <br /> Flat 3B, Mukunt Building, <br />
          20 SV Road, Bandra West, <br />
          Mumbai 400050
        </div>
      </div>
    </div>
  );
};
const SocialButton = ({ link, children, title }) => {
  return (
    <a
      className="cursor-pointer bg-white/[0.06] rounded-full w-10 h-10 flex items-center justify-center hover:bg-black transition-all duration-300 ease"
      href={link ?? "#"}
      aria-label={title}
    >
      {children}
    </a>
  );
};
export default FooterNew;
