import localFont from "next/font/local";
import Header from "./header/header";
import TopBar from "./header/top-bar";
import Footer from "./footer/footer";
import EmailPopup from "@/containers/email-popup";
import SupportChat from "./support-chat";
import { useRouter } from "next/router";
const avenir = localFont({
  src: [
    {
      path: "../../../public/fonts/Avenir-Roman.woff2",
      weight: "400",
      style: "normal",
      preload: true,
      display: "swap",
      fallback: ["system-ui", "arial"],
      adjustFontFallback: "Times New Roman",
    },
  ],
  variable: "--font-avenir",
});
const Layout = ({ children }) => {
  const router = useRouter();
  return (
    <div className={`font-avenir w-full bg-white ${avenir.variable}`}>
      <div
        className={`flex flex-col min-h-screen w-full bg-white z-[10] relative`}
      >
        <TopBar />
        <Header />
        <main
          className="static"
          style={{
            minHeight: "-webkit-fill-available",
            WebkitOverflowScrolling: "touch",
          }}
        >
          {children}
        </main>
        <EmailPopup />
        {router.pathname !== "/support" && <SupportChat />}
      </div>
      <Footer />
    </div>
  );
};
export default Layout;
